<template>
  <div class="receiveBusiness">
    <div class="envelopTotal">
      <statistics :info="envelopInfo"></statistics>
    </div>
    <div class="content">
      <pageTitle :title="$t('CollectionList')" :isBorder="true"></pageTitle>
      <div class="receive">
        <div class="search">
          <search
            @heightChange="heightChange"
            @search="searchChange"
            :status="status"
          ></search>
        </div>
      </div>
      <div class="tableBlock">
        <el-table
          :data="tableData"
          style="width: 100%"
          :height="tableHeight"
          v-tableHeight="{ bottomOffset: 76 }"
          :tableLoading="loading"
        >
          <el-table-column
            prop="receive_no"
            :label="$t('envelopeNumber')"
            width="150"
          >
          </el-table-column>
          <el-table-column prop="name" :label="$t('recipients')" width="200">
            <template slot-scope="scope">
              <userMsgPopover
                :user_name_zh="scope.row.user_name_zh"
                :user_name_en="scope.row.user_name_en"
                :user_id="scope.row.user_id"
              ></userMsgPopover>
              <!-- <span class="mainColor cursor">{{
                scope.row.user_name_en
                  | priorFormat(scope.row.user_name_zh, LOCALE)
              }}</span> -->
            </template>
          </el-table-column>
          <el-table-column :label="$t('companyName')" width="200">
            <template slot-scope="scope">
              {{
                scope.row.company_name_en
                  | priorFormat(scope.row.company_name_zh, LOCALE)
              }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('validUntil')" width="170">
            <template slot-scope="scope">
              {{ scope.row.using_end_time | secondFormat("LL") }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('collectionTime')" width="170">
            <template slot-scope="scope">
              {{ scope.row.receive_time | secondFormat("LLL") }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('Status_red')">
            <template slot-scope="scope">
              <div class="color">
                <div v-show="scope.row.status == 1">{{ $t("unused") }}</div>
                <div v-show="scope.row.status == 2">{{ $t("writtenOff") }}</div>
                <div v-show="scope.row.status == 3">{{ $t("expireds") }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('WriteTime')" width="170">
            <template slot-scope="scope">
              {{ scope.row.verify_use_time | secondFormat("LL") }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('notes')" width="200">
            <template slot-scope="scope">
              {{ scope.row.remarks | textFormat(LOCALE) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('Edit')" width="150">
            <template slot-scope="scope">
              <el-button
                v-if="inspectButton('mem:yrm:acr') && scope.row.status != 2"
                type="text"
                :disabled="scope.row.status == 3"
                @click="showWith(scope.row)"
                >{{ $t("Writeoff") }}</el-button
              >
              <el-button
                v-if="inspectButton('mem:yrm:mdr') && scope.row.status == 2"
                type="text"
                @click="editRemarks(scope.row)"
                >{{ $t("editNotes") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="paginationBlock">
        <el-pagination
          layout="total, prev, pager, next"
          :total="total"
          :page-size="limit"
          :current-page.sync="start"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
    <div>
      <el-dialog
        :title="$t('editNotes')"
        :visible.sync="remarksVisible"
        width="500px"
      >
        <div>
          <el-input
            type="textarea"
            :placeholder="$t('cargosremarksPlaceholder')"
            v-model="remarksVal"
            show-word-limit
            rows="3"
          ></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="remarksVisible = false" size="small">{{
            $t("cancel")
          }}</el-button>
          <el-button
            type="primary"
            @click="confirmRemarks"
            size="small"
            :loading="remarksLoading"
            >{{ $t("confirm") }}</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div>
      <writeOffDialog
        :redId="redId"
        ref="writeOff"
        :mustWriteOff="true"
        @refresh="refresh"
      ></writeOffDialog>
    </div>
  </div>
</template>
<script>
import statistics from "~bac/components/conference/redEnvelopeAdmin/business/receiveStatistics";
import search from "~bac/components/conference/redEnvelopeAdmin/business/filterSearch";
import writeOffDialog from "~bac/components/conference/writeOffDialog/index";
import pageTitle from "~bac/components/common/pageTitle";
import userMsgPopover from "~/baseComponents/card/userMsgPopover";
export default {
  data() {},
  components: {
    statistics,
    pageTitle,
    search,
    writeOffDialog,
    userMsgPopover,
  },
  data() {
    return {
      tableData: [],
      receiveDetail: {},
      routerData: {},
      total: 0,
      limit: 10,
      start: 1,
      tableHeight: 100,
      loading: false,
      remarksLoading: false,
      remarksVisible: false,
      remarksVal: "",
      envelopInfo: {},
      receivePass: "",
      record_id: "",
      status: "",
      envelope_id: "",
      redId:"1111"
    };
  },
  mounted() {
    let params;
    if (this.$route.query.parameter) {
      params = this._decode(this.$route.query.parameter);
      if (params.envelope_id) {
        this.envelope_id = params.envelope_id;
      }
      if (params.status) {
        this.status = params.status;
      }
    }
    this.routerData = params;
    this.getList(params);
    this.getDetail(params);
  },
  methods: {
    async getList(val) {
      try {
        let params = {
          start: this.start - 1,
          limit: this.limit,
          company_id: this.USER_INFO.company_id,
          envelope_id: this.envelope_id,
        };
        let _params = Object.assign(params, val);
        let result = await this.$store.dispatch(
          "baseConsole/searchRedBack",
          _params
        );
        if (result.success) {
          this.tableData = result.data.data;
          this.total = result.data.count;
        }
      } catch (e) {
      }
    },
    async getDetail(val) {
      try {
        let params = {
          envelope_id: val.envelope_id,
          envelope_type: 1,
        };
        let data = await this.$store.dispatch(
          "baseConsole/getCensusNum",
          params
        );
        if (data.success) {
          this.envelopInfo = data.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    refresh() {
      this.getList(this.routerData);
      this.getDetail(this.routerData);
    },
    handlePageChange(page) {
      this.start = page;
      this.getList();
    },
    showWith(row) {
      this.redId=row.record_id
      this.$refs.writeOff.showDialog();

    },
    searchChange(params) {
      this.getList(params);
    },
    heightChange(height) {
      let hei = document.body.clientHeight - height;
      this.tableHeight = hei;
    },
    editRemarks(row) {
      this.record_id = row.record_id;
      this.remarksVal = row.remarks;
      this.remarksVisible = true;
    },
    async confirmRemarks() {
      this.remarksLoading = true;
      try {
        let params = {
          remarks: this.remarksVal,
          record_id: this.record_id,
        };
        let result = await this.$store.dispatch(
          "baseConsole/update_remarks",
          params
        );
        if (result.success) {
          this.$csTip();
          this.remarksVisible = false;
          this.getList(this.routerData);
        }
        this.remarksLoading = false;
      } catch (e) {
        console.log(e);
        this.remarksLoading = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.receiveBusiness {
  .envelopTotal {
    margin-bottom: 12px;
  }
  .content {
    background: #fff;
    padding-bottom: 12px;
    .receive {
      padding: 0 12px;
      .search {
        margin: 20px 0 12px;
      }
    }
  }
}
</style>