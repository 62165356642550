<template>
  <div>
    <div>
      <el-dialog
        :title="$t('redBackHx')"
        :visible.sync="hxdialogVisible"
        @close="clearData"
        :width="LOCALE == 'en' ? '900px' : '600px'"
      >
        <el-form
          :model="numberValidateForm"
          ref="numberValidateForm"
          :label-width="LOCALE == 'en' ? '200px' : '100px'"
          class="demo-ruleForm clearfix"
        >
          <el-form-item
            :label="$t('redBackPass')"
            prop="number"
            :rules="[{ required: true, message: ' ' }]"
          >
            <el-input
              style="width: 250px"
              type="age"
              v-model="numberValidateForm.number"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item class="fr">
            <el-button
              type="primary"
              @click="submitForm('numberValidateForm')"
              >{{ $t("redBackHx") }}</el-button
            >
          </el-form-item>
        </el-form>
        <p class="tips" v-if="tipsShow">{{ $t("noRedBackHx") }}</p>
      </el-dialog>
      <el-dialog
        :title="$t('redBackHx')"
        :visible.sync="confirmhxdialogVisible"
        width="1200px"
        @close="clearData"
      >
        <template>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column :label="$t('RedEnvelopeName')" width="180">
              <template slot-scope="scope">
                {{
                  scope.row.topic_en | priorFormat(scope.row.topic_zh, LOCALE)
                }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('redBackNumber')" width="180">
              <template slot-scope="scope">
                {{ scope.row.receive_no }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('recipients')">
              <template slot-scope="scope">
                {{
                  scope.row.user_name_en
                    | priorFormat(scope.row.user_name_zh, LOCALE)
                }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('discountAmount')">
              <template slot-scope="scope">
                <span>
                  <span v-if="scope.row.has_threshold">
                    {{
                      $t("FullMinus", {
                        full: scope.row.threshold / 100 || 0,
                        minus: scope.row.discount / 100 || 0,
                      })
                    }}
                  </span>
                  <span v-else>
                    {{ $t("Nothreshol") }} {{ scope.row.discount / 100 }}
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('collectionTime')">
              <template slot-scope="scope">
                {{ scope.row.receive_time | secondFormat("LLL") }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('validUntil')">
              <template slot-scope="scope">
                {{ scope.row.using_end_time | secondFormat("LLL") }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('status')">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1" style="color: #909399">{{
                  $t("unused")
                }}</span>
                <span v-if="scope.row.status == 2" style="color: #409eff">{{
                  $t("writtenOff")
                }}</span>
                <span v-if="scope.row.status == 3" style="color: #f56c6c">{{
                  $t("expireds")
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="forms">
          <div class="block changetime">
            <span class="demonstration">{{ $t("hxTime") }}:</span>
            <el-date-picker
              :picker-options="pickerOptions"
              v-model="formOne.date"
              type="date"
              :clearable="false"
              @change="timeChange"
              :placeholder="$t('meetingChangeTimePlace')"
              style="width: 200px"
              size="small"
            >
            </el-date-picker>
            <el-time-picker
              :clearable="false"
              style="width: 200px"
              size="small"
              v-model="formOne.time"
              arrow-control
              :picker-options="{
                selectableRange: selectableRange,
              }"
              :placeholder="$t('meetingChangeTimePlace')"
            >
            </el-time-picker>
          </div>
          <div class="block writeBase">
            <span class="demonstration">{{ $t("notes") }}:</span>
            <el-input
              :placeholder="$t('cargosremarksPlaceholder')"
              size="small"
              maxlength="100"
              v-model="formOne.baseInfo"
            ></el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmhxdialogVisible = false">{{
            $t("cancel")
          }}</el-button>
          <el-button type="primary" @click="saveHx">{{
            $t("Writeoff")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <div>
      <el-dialog
        @close="clearData"
        :title="$t('redBackHx')"
        :visible.sync="directDialog"
        :width="LOCALE == 'en' ? '700px' : '600px'"
      >
        <div>
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            :label-width="LOCALE == 'en' ? '180px' : '100px'"
            class="ruleForm"
          >
            <el-form-item :label="$t('redBackPass')" prop="name">
              <el-input size="small" v-model="ruleForm.number"></el-input>
            </el-form-item>
            <el-form-item :label="$t('hxTime')">
              <div class="timeCheck">
                <el-date-picker
                  :clearable="false"
                  :picker-options="pickerOptions"
                  v-model="formOne.date"
                  type="date"
                  @change="timeChange"
                  :placeholder="$t('meetingChangeTimePlace')"
                  style="width: 200px"
                  size="small"
                >
                </el-date-picker>
                <el-time-picker
                  :clearable="false"
                  style="width: 200px"
                  size="small"
                  v-model="formOne.time"
                  arrow-control
                  :picker-options="{
                    selectableRange: selectableRange,
                  }"
                  :placeholder="$t('meetingChangeTimePlace')"
                >
                </el-time-picker>
              </div>
            </el-form-item>

            <el-form-item :label="$t('notes')" prop="desc">
              <el-input
                type="textarea"
                size="small"
                v-model="ruleForm.baseInfo"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="directDialog = false" size="small">{{
            $t("cancel")
          }}</el-button>
          <el-button type="primary" @click="mustHx" size="small">{{
            $t("Writeoff")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: ["mustWriteOff",'redId'],
  data() {
    return {
      pickerOptions: {
        disabledDate(date) {
          let zero = new Date().setHours(0, 0, 0, 0);
          if (date.getTime() > zero) {
            return true;
          }
          return false;
        },
      },

      hxdialogVisible: false,
      confirmhxdialogVisible: false,
      directDialog: false,
      tipsShow: false,
      formOne: {
        date: "",
        baseInfo: "",
        time: "",
      },
      ruleForm: {
        number: "",
        date: "",
        baseInfo: "",
        time: "",
      },
      selectableRange: "",
      numberValidateForm: {
        number: "",
      },
      tableData: [],
      record_id:''
    };
  },
 
  mounted() {
    this.formOne.date = this.$moment();
    this.formOne.time = new Date();
    this.selectableRange = `00:00:00 - ${this.$moment().format("HH:mm:ss")}`;
  },
  watch: {
     redId(val,old){
      this.record_id =val

    },
    confirmhxdialogVisible(val, oldVal) {
      if (val) {
        (this.formOne = {
          date: "",
          baseInfo: "",
          time: "",
        }),
          (this.formOne.date = this.$moment());
        this.formOne.time = new Date();
        this.selectableRange = `00:00:00 - ${this.$moment().format(
          "HH:mm:ss"
        )}`;
      }
    },
    directDialog(val, oldVal) {
      if (val) {
        (this.formOne = {
          date: "",
          baseInfo: "",
          time: "",
        }),
          (this.ruleForm.number = "");
        this.ruleForm.baseInfo = "";
        this.formOne.date = this.$moment();
        this.formOne.time = new Date();
        this.selectableRange = `00:00:00 - ${this.$moment().format(
          "HH:mm:ss"
        )}`;
      }
    },
    hxdialogVisible(val, oldVal) {
      if (val) {
        this.numberValidateForm = {
          number: "",
        };
      }
    },
  },
  methods: {
    clearData() {
      // this.numberValidateForm = {
      //   number: "",
      // };
      // this.formOne.date = this.$moment();
      // this.formOne.time = new Date();
      // this.selectableRange = `00:00:00 - ${this.$moment().format("HH:mm:ss")}`;
      // this.formOne.baseInfo = "";
      // this.ruleForm.number=""
      // this.ruleForm.baseInfo = ""
    },
    async mustHx() {
      if (!this.ruleForm.number) {
        this.$message.error(this.$t("pleaseInputRedPass"));
        return;
      }
      let data = await this.$store.dispatch("baseConsole/searchRedBack", {
        receive_pass: this.ruleForm.number,
        company_id: this.USER_INFO.company_id,
      });
      if (data.data.data.length) {
        let lastTime = this.$moment(
          this.$moment(this.formOne.date).format("YYYY-MM-DD") +
            " " +
            this.$moment(this.formOne.time).format("HH:mm:ss")
        ).unix();
        let res = await this.$store.dispatch("baseConsole/hxRedBack", {
          receive_pass: this.ruleForm.number || this.numberValidateForm.number,
          company_id: this.USER_INFO.company_id,
          record_id: this.record_id,
          verify_use_time: lastTime,
          remarks: this.ruleForm.baseInfo,
          verify_use_operator: this.USER_INFO.id,
        });
        if (res.success) {
          this.$message({
            message: this.$t("succeed"),
            type: "success",
          });
          this.$emit("refresh");
          this.directDialog = false;
        } else {
          this.$message.error(this.$t("failed"));
        }
      } else {
        this.$message.error(this.$t("noRedBackHx"));
      }
    },
    showDialog() {
      if (this.mustWriteOff) {
        this.directDialog = true;
      } else {
        this.hxdialogVisible = true;
      }
    },
    async saveHx() {
      if (this.tableData[0].status == 2) {
        this.$message({
          message: this.$t("hasHxRed"),
          type: "warning",
        });
        return false;
      }
      if (this.tableData[0].status == 3) {
        this.$message({
          message: this.$t("redHasExpried"),
          type: "warning",
        });
        return false;
      }
      let lastTime = this.$moment(
        this.$moment(this.formOne.date).format("YYYY-MM-DD") +
          " " +
          this.$moment(this.formOne.time).format("HH:mm:ss")
      ).unix();
      let data = await this.$store.dispatch("baseConsole/hxRedBack", {
        receive_pass: this.numberValidateForm.number,
        company_id: this.USER_INFO.company_id,
        record_id: this.tableData[0].record_id,
        verify_use_time: lastTime,
        remarks: this.formOne.baseInfo,
        verify_use_operator: this.USER_INFO.id,
      });
      if (data.success) {
        this.$message({
          message: this.$t("succeed"),
          type: "success",
        });
        this.$emit("refresh");
        this.confirmhxdialogVisible = false;
      } else {
        this.$message.error(this.$t("failed"));
      }
    },
    timeChange(e) {
      if (this.$moment(e).unix() == this.$moment().startOf("day").unix()) {
        this.formOne.time = new Date();
        this.selectableRange = `00:00:00 - ${this.$moment().format(
          "HH:mm:ss"
        )}`;
      } else {
        this.selectableRange = "00:00:00 - 23:59:59";
      }
    },
    submitForm(formName) {
      this.tipsShow = false;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let data = await this.$store.dispatch("baseConsole/searchRedBack", {
            receive_pass: this.numberValidateForm.number,
            company_id: this.USER_INFO.company_id,
          });
          if (data.data.data.length) {
            this.tableData = data.data.data;
            this.hxdialogVisible = false;
            this.confirmhxdialogVisible = true;
          } else {
            this.tipsShow = true;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.demo-ruleForm {
  display: flex;
  align-items: center;
}
.tips {
  font-size: 16px;
  color: #f56c6c;
  padding-left: 100px;
}
.forms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  .block {
    display: flex;
    align-items: center;
    span {
      padding-right: 10px;
    }
  }
  .changetime {
    width: 50%;
    justify-content: space-around;
  }
  .writeBase {
    width: 50%;
    span {
      white-space: nowrap;
    }
  }
}
.timeCheck {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
