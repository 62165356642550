<template>
  <!-- 领取列表搜索框 -->
  <div class="filterSearch">
    <div class="clearfix">
      <el-input
        class="fl"
        v-model="search.receive_no"
        :placeholder="$t('redenvelopenumber')"
        size="small"
        style="max-width: 15%"
      ></el-input>
      <el-input
        size="small"
        v-model="search.user_name"
        class="inputWidth fl"
        :placeholder="$t('EnterLQname')"
      ></el-input>
      <CompanySelect
        class="fl"
        style="width: 15%"
        v-model="search.company_name"
      ></CompanySelect>
      <el-input
        size="small"
        v-model="search.email_or_mobile"
        class="inputWidth fl"
        :placeholder="$t('enterEmailMpbile')"
      ></el-input>
      <el-select
        class="fl inputWidth"
        v-model="search.status"
        :placeholder="$t('allRedEnvelope')"
        size="small"
      >
        <el-option
          v-for="item in searchOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="box fl" @click="show_more = !show_more">
        <i
          :class="[show_more ? 'el-icon-minus' : 'el-icon-plus']"
          class="i_rotate"
        ></i>
      </div>
      <span class="fr">
        <el-button size="small" type="primary" @click="searchChange">{{
          $t("search")
        }}</el-button>
        <el-button size="small" @click="resetChange">{{
          $t("reset")
        }}</el-button>
      </span>
    </div>
    <div v-show="show_more">
      <div class="clearfix haveSelect">
        <el-date-picker
          class="form_item"
          size="small"
          v-model="search.time"
          type="daterange"
          range-separator="-"
          :start-placeholder="$t('validUntil')"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
        <el-date-picker
          class="dateWidth"
          size="small"
          v-model="search.timer"
          type="daterange"
          range-separator="-"
          :start-placeholder="$t('WriteTime')"
          :end-placeholder="$t('enterDate')"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
        <el-input
          v-model="search.remarks"
          :placeholder="$t('notes')"
          size="small"
          style="max-width: 18%"
        ></el-input>
      </div>
    </div>
  </div>
</template>
<script>
import CountrySelect from "~/baseComponents/CountrySelect";
import CitySelect from "~/baseComponents/CitySelect";
import CompanySelect from "~/baseComponents/CompanySelect";
import majorLine from "~bac/components/company/business/majorLine";
export default {
  components: {
    CountrySelect,
    CitySelect,
    CompanySelect,
    majorLine,
  },
  props: ["status"],
  data() {
    return {
      search: {},
      searchOptions: [
        {
          value: "1",
          label: this.$t("unused"),
        },
        {
          value: "2",
          label: this.$t("writtenOff"),
        },

        {
          value: "3",
          label: this.$t("expireds"),
        },
      ],
      show_more: false,
      industry: [],
      searchResult: [],
    };
  },
  watch: {
    show_more() {
      if (this.show_more) {
        this.$emit("heightChange", 467);
      } else {
        this.$emit("heightChange", 425);
      }
    },
    status() {
      this.search.status = this.status.toString();
    },
  },
  methods: {
    searchChange() {
      let params = Object.assign({}, this.search);
      if (this.search.time) {
        params.start_using_end_time =
          this.search.time && moment(this.search.time[0]).unix();
        params.end_using_end_time =
          this.search.time && moment(this.search.time[1]).unix();
      }
      if (this.search.timer) {
        params.start_verify_use_time =
          this.search.timer && moment(this.search.timer[0]).unix();
        params.end_verify_use_time =
          this.search.timer && moment(this.search.timer[1]).unix();
      }
      delete params.time;
      delete params.timer;
      this.$emit("search", params);
    },
    resetChange() {
      this.search = {};
      this.search.company_name = "";
    },
  },
};
</script>
<style lang="less" scoped>
.filterSearch {
  .i_rotate {
    font-size: 18px;
    color: #dcdfe6;
    cursor: pointer;
    border: 1px solid #dcdfe6;
    padding: 5px;
    border-radius: 3px;
  }
  .inputWidth {
    width: 15%;
    margin: 0 12px;
  }
  .dateWidth {
    margin: 0 16px;
  }
  .haveSelect {
    margin: 10px 0;
    .companyScreen {
      width: 90px;
      line-height: 32px;
    }
    .screenBtn {
      margin-right: 5px;
    }
  }
  .midSearch {
    padding: 20px 0;
    .screenBtn {
      margin-right: 15px;
    }
    p {
      line-height: 32px;
      font-size: 14px;

      padding-right: 10px;
    }
  }
}
</style>