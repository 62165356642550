<template>
  <div class="statistics">
    <el-row>
      <el-col :span="14">
        <div class="clearfix left">
          <div class="fl leftTitle">
            <div
              class="activity textOverflow"
              :title="info.topic_en | priorFormat(info.topic_zh, LOCALE)"
            >
              {{ info.topic_en | priorFormat(info.topic_zh, LOCALE) }}
            </div>
            <div v-if="info.threshold && info.discount">
              {{ $t("discount") }}：{{
                $t("FullMinus", {
                  full: info.threshold / 100 || 0,
                  minus: info.discount / 100 || 0,
                })
              }}
            </div>
            <div v-show="info.using_time_type == 1">
              {{ $t("effectiveDate") }}：<span>
                {{ info.using_start_time | secondFormat("LL") }} -
                {{ info.using_end_time | secondFormat("LL") }}</span
              >
            </div>
            <div v-show="info.using_time_type == 2">
              {{ $t("effectiveDate") }}：{{
                $t("todayAvailable", { day: info.effective_days })
              }}
            </div>
            <div v-show="info.using_time_type == 3">
              {{ $t("effectiveDate") }}：{{
                $t("nextAvailable", { day: info.effective_days })
              }}
            </div>
          </div>
          <div class="fr">
            <div class="clearfix">
              <div class="color tc fr verification">
                {{ $t("targetRelease") }}
              </div>
            </div>
            <div class="clearfix item">
              <div class="fl labelLeft mainColor">{{ $t("quantity2") }}：</div>
              <div class="fr tc size">{{ info.total_num || 0 }}</div>
            </div>
            <div class="clearfix item">
              <div class="fl money">
                {{ $t("amount") }}(<span v-if="info.currency == 2">$</span>
                <span v-else>￥</span>)：
              </div>
              <div class="fr tc size">
                {{ info.total_amount / 100 || 0 }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="tc already">
          <div class="color">{{ $t("received") }}</div>
          <div class="num">{{ info.geted_num || 0 }}</div>
          <div class="num">{{ info.geted_amount / 100 || 0 }}</div>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="tc balance">
          <div class="color">{{ $t("writtenOff") }}</div>
          <div class="num">{{ info.verify_num || 0 }}</div>
          <div class="num">
            {{ info.verify_amount / 100 || 0 }}
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.statistics {
  padding: 32px;
  height: 162px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.19);
  .left {
    padding-right: 60px;
    border-right: 1px solid #e8e8e8;
    .leftTitle {
      max-width: 280px;
      .activity {
        font-size: 20px;
        color: #333;
        margin-top: 0;
      }
      div {
        color: rgba(0, 0, 0, 0.45);
        margin-top: 12px;
      }
    }
    .verification {
      width: 100px;
      color: rgba(0, 0, 0, 0.45);
    }
    .item {
      line-height: 40px;
      div {
        text-align: right;
      }
    }
    .money {
      color: #dd9740;
    }
  }
  .already {
    font-size: 24px;
    border-right: 1px solid #e8e8e8;
  }
  .balance {
    font-size: 24px;
    .explain {
      display: none;
    }
    i:hover {
      .explain {
        display: block;
      }
    }
  }
  .record {
    span {
      border: 1px solid #0083f6;
      border-radius: 50%;
      font-size: 10px;
      padding: 0 2px;
    }
  }
  .num {
    line-height: 40px;
  }
  .size {
    font-size: 24px;
    width: 100px;
    margin-left: 30px;
  }
  .color {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
</style>